.custom-cluster-icon {
  background-color: #233bc2;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 33px;
  height: 33px;
}
