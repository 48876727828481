.form-step-2 {
  max-width: 960px;
  margin: 10px auto;
}

.text-center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
  /* color: #cccccc; */
}

/* Dropzone */
.dropzone {
  padding: 30px;
  width: 100%;
  height: 20vh;
  /* border: 2px dashed #cccccc; */
  outline: none;
  cursor: pointer;
}
.dropzone-active {
  background-color: #f3ffe6;
  border: 2px solid #cccccc;
}
.dropzone-reject {
  background-color: #ffcccc;
  border: 2px solid #cccccc;
}

.dropzone-content {
  align-self: center;
  font-size: 24px;
}

/* Images */
.file-list {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  /* display: block; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.file-item {
  /* width: calc(33% - 20px); */
  /* height: 300px; */
  width: 300px;
  height: 200px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin: 10px;
}
.file-img {
  width: 100%;
  height: 100%;
}
