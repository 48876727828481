.custom-popup {
  z-index: 0;
}

.custom-popup.hidden {
  visibility: hidden;
}

.custom-popup .leaflet-popup-content-wrapper {
  /* background: #2c3e50;
  color: #fff;
  font-size: 16px;
  line-height: 24px; */
  border-radius: 3px;
  /* padding: 1px;
  margin-bottom: 0px; */
}

.custom-popup .leaflet-popup-content {
  margin: 0px 0px -5px 0px;
  /* margin-top: 0px;
  margin-right: 0px;
  margin-bottom: -4px;
  margin-left: 0px;  */
}

/* .custom-popup .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
} */

/* .custom-popup .leaflet-popup .leaflet-zoom-animated {
  margin-bottom: 5px;
} */
